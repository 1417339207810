
import desert_bg from "assets/img/bg/desert.jpg";
import desert_dark_bg from "assets/img/bg/desert_night.jpg";
import vip_logo from "assets/img/vip.svg";
import { environment } from "AmplifyConfig";

const env = environment;
const Homepage = "https://user360-portal-test.s3.amazonaws.com/";

var LayoutVariables = {
  brand: "User 360°",
  brand_logo_URL: Homepage + "logo.png",
  footer_logo: Homepage + "tcs.png",
  userinfo_background: Homepage + "userbg.jpg",
  nothing_background: desert_bg ,
  nothing_background_dark: desert_dark_bg,
  vip: vip_logo,
  profile_images_bucket: "https://aap-user360-userprofiles.s3.amazonaws.com/",
  default_user_profile: Homepage + "default-avatar.png",
  CCP_URL: "https://tcsi2i.awsapps.com/connect/ccp-v2/",
  CCP_SignOut: "https://tcsi2i.awsapps.com/connect/logout"
};

switch (env){
  case "prod":
    LayoutVariables.CCP_URL = "https://tcsaap.awsapps.com/connect/ccp-v2/";
    LayoutVariables.CCP_SignOut = "https://tcsaap.awsapps.com/connect/logout";
    break;
  
  default:
    break;
}

export default LayoutVariables;
