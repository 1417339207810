// ---- MAIN CONFIGURATION ---- //
// Please set the environment:
const environment = "prod"; // dev, prod, or local

// Please set your cognito domain:
const CognitoDomain = "aap-user360.auth.us-east-1.amazoncognito.com";

// Please set your Cognito user pool ID:
const userPoolId = "us-east-1_1uUYN1JRv";


let redirectUrl = () => {
    // Sets redirect urls depending on environment
    switch(environment) {
        case "dev":
            return "https://dev.aap-user360.tcsi2i.com"
            //return "https://d2eij7vhmao7c1.cloudfront.net"
        case "prod" :
            return "https://aap-user360.tcsi2i.com"
            //return "https://d19xvuu719jfcr.cloudfront.net"
        default :
            return "http://localhost:8000/"
    }
}

let endpoint = () => {
    switch(environment) {
        case "prod":
            return "https://kk2hc1f04h.execute-api.us-east-1.amazonaws.com/"
        case "dev":
        default :
            return "https://bggd9t0a2l.execute-api.us-east-1.amazonaws.com/"
    }
}


let stage = () => {
    switch(environment) {
        case "prod":
            return "PROD/"
        case "dev":
        default :
            return "DEV/"
    }
}


let userPoolWebClientId = () => {
    // Sets redirect urls depending on environment
    switch(environment) {
        case "prod" :
            // Only for prod
            return "57j2n8a6moi4hqi59er3okiv31"
        default :
            // Dev and local
            return "6npptf6hqvg74o5e679jqd19q4"
    }
}

let cookieDomain = () => {
    // Sets cookie domain depending on environment
    switch(environment) {
        case "prod" :
            return ".aap-user360.tcsi2i.com"
            //return ".d19xvuu719jfcr.cloudfront.net"
        case "dev":
            return ".dev.aap-user360.tcsi2i.com"
            //return ".d2eij7vhmao7c1.cloudfront.net"
        default :
            return "localhost"
    }
}


export const AmplifyConfig = {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: userPoolId,
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: userPoolWebClientId(), 
  
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is st to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: cookieDomain(), 
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: "lax", //"strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },
      // OPTIONAL - Hosted UI configuration
      oauth: {
        // BASE DOMAIN
        domain: CognitoDomain, //No incluir "https://" pues la librería lo incluye por sí misma.
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        // --- REDIRECT URLS ---
        redirectSignIn: redirectUrl(),
        redirectSignOut: redirectUrl(),
        responseType: "code", // code or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
        endpoints: [
            {
                name: "user360",
                endpoint: endpoint() + stage()
            } 
        ],
    }
};
  
export { environment };