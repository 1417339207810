// ---- MAIN CONFIGURATION ---- //
// Please set the environment:
const environment = "dev"

var api_stage;
var host;

switch(environment) {
  case "prod":
    api_stage = "PROD";  
    host = `https://kk2hc1f04h.execute-api.us-east-1.amazonaws.com/${api_stage}/`
    break;

  case "dev":
  default:
    api_stage = "DEV";
    host = `https://bggd9t0a2l.execute-api.us-east-1.amazonaws.com/${api_stage}/`;
    break
} 
  

//Methods below are on host
const ticketQuery = "snow-query";
const commandQuery = "user360-ssm-api";
const lastInteraction = "last-iteractions";
const agents_profile = "agent-profile";
const snowAttachment = "snow-query";
const snowKnowledgeBase = "snow-query";
const uploadQuery = "upload";
const jsonStatsQuery = "json-stats";


const API_AgentProfileGet = {
  endpoint: host + agents_profile,
  method: "get",
  getProfile: {
    username: "",
  },
};


const API_AgentProfilePost = {
  endpoint: host + agents_profile,
  method: "post",
  postProfile: {
    username: "",
  },
  data: {
    profile: {
      Email: "",
      FirstName: "",
      LastName: "",
      Address: "",
      City: "",
      Country: "",
      PostalCode: "",
      About: "",
    },
  },
};

const API_AgentSettings = {
  endpoint: host + agents_profile,
  method: "post",
  headers: {
    username: "",
  },
  data: {
    settings: {
      DarkMode: "",
      BgSB: "",
      MiniSB: "",
      //FontSB: "",
    },
  },
};

/* const API_LinkSnowAccount = {
  endpoint: host + snowLinkAcc,
  method: "post",
  headers: {
    connectUser: "",
  },
  data: {
    snowUser: "",
    snowPassword: "",
  },
}; */

/* const API_CheckSnowAccount = {
  endpoint: host + snowLinkAcc,
  method: "get",
  headers: {
    connectUser: "",
  },
}; */

const API_LastInteractions = {
  endpoint: host + lastInteraction,
  method: "get",
  getLastInteraction: {
    phoneNumber: "",
  },
};

//This API posts a new ticket in servicenow with the parameters received.
const API_NewTicket = {
  endpoint: host + ticketQuery,
  method: "post",
  data: {
    table: "incident",
    data: {
      assigned_to: "",
      assignment_group: "",
      short_description: "",
      description: "",
      contact_type: "",
      state: "",
      impact: "",
      urgency: "",
      caller_id: "",
      close_code: "",
      close_notes: "",
    },
  },
  headers: {
    query: "create_ticket",
  },
};

const API_SnowAttachment = {
  endpoint: host + snowAttachment,
  method: "post",
  headers: {
    file_type: "",
    table_sys_id: "",
    file_name: "",
    connectUser: "",
  },
  data: {},
};

//This API GET the file_sys_id or the Binary file from a ticket
const API_GetSnowAttachment = {
  endpoint: host + snowAttachment,
  method: "get",
  headers: {
    query : "get_info_attachment",
    keys : "",
    //file_sys_id: "",
    //table_sys_id: "",
  },
};

//This API GET the file_sys_id or the Binary file from a ticket
const API_GetKnowledgeArticle = {
  endpoint: host + snowKnowledgeBase,
  method: "get",
  data: {
    query: "knowledge_base",
    keys: "",
  },
};

const API_UpdateTicket = {
  endpoint: host + ticketQuery,
  method: "put",
  data: {
    u_name: "",
    short_description: "",
    assignment_group: "",
    cmdb_ci: "",
    impact: "",
    description: "",
    active: "",
    sys_updated_on: "",
    priority: "",
    sys_id: "",
    number: "",
    contact_type: "",
    urgency: "",
    opened_by: "",
    caller_id: "",
    u_call_number: "",
    location: "",
    state: "",
    category: "",
    subcategory: "",
    work_notes: "",
    assigned_to: ""
  },
  headers: {
    query : "modify_ticket",
    ticket_number : ""
  },
};


//This API gets data from servicenow with the parameters received.
const API_QueryData = {
  endpoint: host + ticketQuery,
  method: "get",
  getUserInfo: {
    query: "userinfo",
    phoneNumber: "",
  },
  getUserLocation: {
    query: "location",
    user_id_location: "",
  },
  getDepartment: {
    query: "department",
    user_id_department: "",
  },
  getCompany: {
    query: "company",
    company_id: "",
  },
  getHardware: {
    query: "hardware",
    user_id: "",
  },
  getTickets: {
    query: "open_tickets",
    sys_user_id: "",
  },
  getClosedTickets: {
    query: "closed_tickets",
    sys_user_id: "",
  },
  getStoreTickets: {
    query: "tickets_by_store",
    keys: "",
  },
  getTicketsLastMonth: {
    query: "latest_tickets",
    sys_user_id: "",
  },
  getTicketSearch: {
    query: "search_ticket",
    keys: "",
  },
  getUserSearch: {
    query: "user_search",
    keys: "",
  },
  getQuickSearch: {
    query: "quick_search",
    keys: "",
  },
  getAssigmentGroups: {
    query: "assignment_group",
    keys: "",
  },
  getAssignedTo: {
    query: "assigned_to",
    keys: "",
    group_id: "",
  },
};


const API_UploadImage = {
  endpoint: host + uploadQuery,
  method: "put",
  data: {},
};


/// ****** For more info check the README-ssm-api.md on the proyect root. ******
const API_SSM_describeInstanceInformation = {
  endpoint: host + commandQuery,
  method: "post",
  data: {
    params: {
      Filters: [
        {
          Key: "InstanceIds",
          Values: [""],
        },
      ],
    },
    type: "describeInstanceInformation",
  },
};


const API_SSM_getCommandInvocation = {
  endpoint: host + commandQuery,
  method: "post",
  data: {
    CommandId: "e2188af3-fc8a-4773-b159-05d11c44c31a",
    InstanceId: "i-0674d31621d77e847",
    type: "getCommandInvocation",
  },
};


const API_SSM_sendCommand = {
  endpoint: host + commandQuery,
  method: "post",
  data: {
    doc: "AWS-InstallApplication",
    instance: "i-0674d31621d77e847",
    type: "sendCommand",
  },
};


const API_Unlock_Account = {
  endpoint: host + ticketQuery,
  method: "put",
  headers: {
    connectUser: ""
  },
  data: {
    table: "sys_user",
    number: "",
    data: {
      locked_out: null,
    },
  },
};


const API_GetJsonStats = {
  endpoint: host + jsonStatsQuery,
  method : "get",
  headers : {},
  data: {},
  monthly: {
    query: "monthly"
  },
  daily: {
    query: "daily"
  }
}



module.exports = {
  environment,
  host,
  API_NewTicket,
  API_UpdateTicket,
  API_QueryData,
  API_SSM_describeInstanceInformation,
  API_SSM_getCommandInvocation,
  API_SSM_sendCommand,
  API_Unlock_Account,
  API_LastInteractions,
  API_AgentProfileGet,
  API_AgentProfilePost,
  API_UploadImage,
  API_GetSnowAttachment,
  API_SnowAttachment,
  API_GetKnowledgeArticle,
  /* API_LinkSnowAccount,
  API_CheckSnowAccount, */
  API_AgentSettings,
  API_GetJsonStats,
};
