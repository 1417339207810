// ---- MAIN CONFIGURATION ---- //
// Please set the environment:
const environment = "dev" // dev, test, prod, u360

let SNOW_INSTANCE_URL;

switch (environment){
    case "dev":
        SNOW_INSTANCE_URL = "https://advancedev.service-now.com/";
        break;

    case "test" :
        SNOW_INSTANCE_URL = "https://advancetest.service-now.com/";
        break;

    case "prod" :
        SNOW_INSTANCE_URL = "https://advance.service-now.com/";
        break; 

    default : 
        SNOW_INSTANCE_URL = "https://dev52437.service-now.com/";
        break;
}

// Ticket Details Root URL
const SNOW_TICKET_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=incident.do?sys_id=";
// KB Details Root URL
const SNOW_KB_URL = SNOW_INSTANCE_URL + "/kb_view.do?sysparm_article=";
// Create ticket URL
const SNOW_CREATE_TICKET_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=incident.do?sys_id=-1"; 
// Create Call URL
const SNOW_CREATE_CALL_URL = SNOW_INSTANCE_URL + "/nav_to.do?uri=new_call.do?sys_id=-1";

export {
    SNOW_INSTANCE_URL,
    SNOW_TICKET_URL,
    SNOW_KB_URL,
    SNOW_CREATE_TICKET_URL,
    SNOW_CREATE_CALL_URL
};
